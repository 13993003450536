<template>
    <div>
        <a-modal title="修改账号" :width="540" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    保存
                </a-button>
            </template>
            <a-spin :spinning="loading">
                <div>
                    <div class="flex  mt10">
                        <a-input class="woyaou-input" style="width: 460px;" prefix="名称" placeholder="请输入账号姓名" v-model="edit_account_info.username" :maxLength="20">
                        </a-input>
                    </div>
                    <div class="flex  mt20">
                        <a-input class="woyaou-input" style="width: 460px;" prefix="手机号" placeholder="请输入手机号" v-model="edit_account_info.mobile" :maxLength="20">
                        </a-input>
                    </div>
					<div class="flex  mt20">
						<a-input class="woyaou-input" style="width: 460px;" prefix="门店数量" placeholder="请输入门店数量" v-model="edit_account_info.store_number" :maxLength="20">
						</a-input>
					</div>
                    <div class="flex  mt20">
                        <a-input class="woyaou-input" type="password" style="width: 460px;" prefix="密码" placeholder="请输入账号密码" v-model="edit_account_info.password" :maxLength="20">
                        </a-input>
                    </div>
                </div>
            </a-spin>
        </a-modal>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        edit_account_id: {
            type: Number,
            default: 0,
        },
        edit_account_info: {
            type: Object,
            default: 0,
        },
    },
    data() {
        return {
            loading: false,
            confirmLoading: false,
            form: {
                username: '',
                mobile: '',
                password: '',
            }
        }
    },
    methods: {

        /**
         * 取消弹窗
         */
        handleCancel() {
            this.$emit("cancel");
        },

        /**
         * 提交数据
         */
        onSubmit() {
            if (this.confirmLoading == true) return;
            this.confirmLoading = true;
            this.$http.api('api/user/editUser', {
                username: this.edit_account_info.username,
                mobile: this.edit_account_info.mobile,
                password: this.edit_account_info.password,
				store_number:this.edit_account_info.store_number,
                account_id: this.edit_account_info.id
            }).then(res => {
                this.$message.success('修改成功', 1, () => {
                    this.confirmLoading = false;
                    this.$emit('ok');
                })

            }).catch(res => {
                console.log(res);
                this.confirmLoading = false;
            })
        },

    }
}
</script>

<style>
</style>
