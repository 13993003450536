<template>
    <div>

        <div class="">
            <a-button type="primary" icon="plus" @click="addAccountAct()">添加账号</a-button>
        </div>
        <div>
            <div class="form-search-box mt20">
                <a-form layout="inline">
                    <a-form-item label="帐号">
                        <a-input v-model="search.username" placeholder="请输入帐号名称"></a-input>
                    </a-form-item>

                    <a-form-item label="手机号">
                        <a-input v-model="search.mobile" placeholder="请输入手机号"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-button @click="searchAct" type="primary">查询</a-button>
                        <a-button @click="cancelAct1" class="ml10">取消</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>

        <div class="mt20">
            <div class="wxb-table-white">
                <!-- rowKey="account_id" -->
                <a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas" :loading="loading">

                    <div class="flex alcenter center cl-theme" slot="add_store_number" slot-scope="add_store_number,record">
                        {{add_store_number}}个
                    </div>

                    <div class="flex alcenter center cl-theme clickAct" slot="store_number" slot-scope="store_number,record">
                        {{store_number}}个
                    </div>

                    <template slot="action" slot-scope="text,record">
                        <a class="menu-act" href="javascript:;" @click="seeShopAct(record)">
                            <i class="iconfont ft14 iconshop_operation01"></i>
                            <span class="ml10">查看门店</span>
                        </a>
                        <a class="menu-act" href="javascript:;" @click="editShopAct(record)" style="margin-left:20px">
                            <i class="iconfont ft14 iconshop_operation01"></i>
                            <span class="ml10">修改用户</span>
                        </a>
                        <!-- <div class="flex center">
                            <a-dropdown placement="bottomRight">
                                <span class="more-act">
                                    <i class="iconfont iconmore_gray"></i>
                                </span>
                                <a-menu slot="overlay">
                                    <a-menu-item>
                                       
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </div> -->
                    </template>
                </a-table>
            </div>

            <div v-if="seeShopVisible">
                <see-shop :visible="seeShopVisible" :account_id="account_id" @cancel="cancelSeeShop"></see-shop>
            </div>

            <div v-if="addAccountVisible">
                <add-account :visible="addAccountVisible" @cancel="cancelAddAccount" @ok="okAddAccount"></add-account>
            </div>
            <div v-if="editAccountVisible">
                <edit-account :visible="editAccountVisible" :edit_account_id="edit_account_id" :edit_account_info="edit_account_info" @cancel="cancelEditAccount" @ok="okEditAccount"></edit-account>
            </div>
        </div>
    </div>
</template>

<script>
import {
    listMixin
} from '../../common/mixin/list.js';
import seeShop from './components/index/modal/seeShop.vue';
import addAccount from './components/index/modal/addAccount.vue';
import editAccount from './components/index/modal/editAccount.vue';
export default {
    mixins: [listMixin],
    components: {
        seeShop,
        addAccount,
        editAccount,
    },
    data() {
        return {
            loading: false,
            seeShopVisible: false,
            addAccountVisible: false,
            editAccountVisible: false,
            account_id: 0,
            edit_account_id: 0,
            edit_account_info: {},
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            search: {
                username: '',
                mobile: "",
            },
            columns: [
                { title: '编号', dataIndex: 'id', align: 'center', ellipsis: true },
                { title: '帐号名称', dataIndex: 'username', align: 'center', ellipsis: true },
                { title: '手机号', dataIndex: 'mobile', align: 'center', ellipsis: true },
                { title: '开通时间', dataIndex: 'jointime', align: 'center', ellipsis: true },
                { title: '最后活跃时间', dataIndex: 'prevtime', align: 'center', ellipsis: true },
                { title: '创建门店数', dataIndex: 'add_store_number', align: 'center', scopedSlots: { customRender: 'add_store_number' } },
                { title: '可创建门店数', dataIndex: 'store_number', align: 'center', scopedSlots: { customRender: 'store_number' } },
                { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } },

            ],
            datas: [],
        }
    },
    created() {

    },
    methods: {
        cancelAct1() {
            this.pagination.current = 1;
            this.search.username = ''
            this.search.mobile = ''
            this.getLists();
        },
        getLists() {
            if (this.loading == true) return;
            this.loading = true;
            this.$http.api('api/user/ListUser', {
                keyword: this.search.keyword,
                username: this.search.username,
                mobile: this.search.mobile,
                limit: this.pagination.pageSize,
                page: this.pagination.current,
            }).then(res => {
                this.pagination.total = res.data.total;
                this.datas = res.data.data;
            }).catch(res => {
                console.log(res);
            }).finally(() => {
                this.loading = false;
            })

        },

        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },

        //查看商铺
        seeShopAct(record) {
            this.account_id = record.id;
            this.seeShopVisible = true;
        },
        cancelSeeShop() {
            this.seeShopVisible = false;
        },

        //添加账号
        addAccountAct() {
            this.addAccountVisible = true;
        },
        cancelAddAccount() {
            this.addAccountVisible = false;
        },
        okAddAccount() {
            this.addAccountVisible = false;
            this.getLists();
        },
        //修改账号
        editShopAct(record) {
            this.edit_account_id = record.id;
            this.edit_account_info = record
            this.$set(this.edit_account_info, 'password', '')
            this.editAccountVisible = true;
        },
        cancelEditAccount() {
            this.editAccountVisible = false;
        },
        okEditAccount() {
            this.editAccountVisible = false;
            this.getLists();
        }
    }
}
</script>

<style>
</style>
